/* eslint-disable no-underscore-dangle */
import { get, post, put, remove, TError } from 'api';
import { processError } from 'utils/alert';
import { IFlag, IUser, IUserInfo, IUserSession } from 'store/users';

export const fetchUsers = async (): Promise<IUser[]> => {
  try {
    const { data } = await get<IUser[]>('admin_users');
    return data;
  } catch (error) {
    processError(error as TError);
    return [];
  }
};

export const createUser = async (
  user: Pick<
    IUser,
    'email' | 'admin' | 'moderator' | 'user_bots' | 'full_name' | 'company'
  > & {
    password: string;
  }
): Promise<IUser | 'existed' | null> => {
  try {
    const { data } = await post<IUser>('admin_users', user as never);
    return data;
  } catch (error) {
    if ((error as { message: string })?.message?.includes('existed'))
      return 'existed';
    processError(error as TError);
    return null;
  }
};

export const updateUser = async (
  userId: IUser['id'],
  user: Partial<IUser>
): Promise<IUser | null> => {
  try {
    const { data } = await put<IUser>(['admin_users', userId], user);
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const deleteUser = async (id: string): Promise<boolean> => {
  try {
    await remove(['admin_users', id]);
    return true;
  } catch (error) {
    processError(error as TError);
    return false;
  }
};

export const fetchUserProfile = async (
  chat_id: string
): Promise<{ data: IUserInfo; updated: string } | null> => {
  try {
    const { data } = await get<{
      last_update: string;
      user_profile: IUserInfo;
    }>(['admin_users', 'user_profile'], { chat_id });
    return { data: data.user_profile, updated: data.last_update };
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const generateUserProfile = async (
  chat_id: string
): Promise<{ data: IUserInfo; updated: string } | null> => {
  try {
    const { data } = await post<{
      last_update: string;
      user_profile: IUserInfo;
    }>(['admin_users', 'generate_user_profile'], { chat_id });
    return { data: data.user_profile, updated: data.last_update };
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const fetchUserHistory = async (
  user_id: string,
  bot_id: string
): Promise<IUserSession[]> => {
  try {
    const { data } = await get<IUserSession[]>(
      ['admin_users', 'user_history'],
      {
        user_id,
        bot_id,
      }
    );
    return data;
  } catch (error) {
    processError(error as TError);
    return [];
  }
};

export const fetchFlags = async (): Promise<IFlag[]> => {
  try {
    const { data } = await get<IFlag[]>(['admin_users', 'red_flags']);
    return data;
  } catch (error) {
    processError(error as TError);
    return [];
  }
};
