/* eslint-disable no-undef */
import { action, observable, runInAction } from 'mobx';

import { fetchBots, createBot, updateBot, deleteBot, copyBot } from 'api/bots';
import { loadUsers } from './users';

export interface IBotIntentContent {
  sub_name: string;
  sub_content: string;
}

export interface IBotIntent {
  name: string;
  content: Array<IBotIntentContent>;
}

export interface IBotTime {
  minute: number;
  system_message: string;
}

export interface IBotEvaluatedFlag {
  metric: string;
  threshold: number;
}

interface IBotInitialPrompt {
  category: string;
  content: string;
  is_dynamic?: boolean;
  name?: string;
  prompt_id?: string;
}

interface IBotUtilsPrompt {
  name?: string;
  content: string;
  prompt_id?: string;
  is_dynamic?: boolean;
}

interface IBotUserProfilingData {
  characteristic: string;
  description: string;
  is_initial: boolean;
}

export type TBotVoice = string;

export type TBotSettings = {
  extract_memory?: boolean;
  llm_base_url?: string;
  session_timeout?: number;
  chat_lifespan?: number[];
  max_listening_pause_seconds: number;
  language?: string;
};

export interface IBot {
  id: string;
  name: string;
  host?: string;
  source?: boolean;
  owner_id: string;
  owner_key: string;
  evaluated_flags: Array<IBotEvaluatedFlag>;
  initial_prompts: Record<string, IBotInitialPrompt>;
  intents: Array<IBotIntent>;
  user_profiling_data: Array<IBotUserProfilingData>;
  utils_prompts: Record<string, IBotUtilsPrompt>;
  voices: Array<TBotVoice>;
  timestamps?: Array<IBotTime>;
  extra?: IBotHRExtra;
  settings?: TBotSettings;
}

export interface IBotHRExtra {
  hr: boolean;
}

export interface IBotLanguage {
  code: string;
  language_name: string;
}

export type TBotExtractedMemory = Array<{
  title: string;
  items: Array<string>;
}>;

interface IBots {
  inited: boolean;
  list: IBot[];
}

const defState: IBots = {
  inited: false,
  list: [],
};

const bots = observable<IBots>(defState);

export const clearStore = action(() => Object.assign(bots, defState));

export const loadBots = async () => {
  const data = await fetchBots();
  runInAction(() => {
    bots.list = data;
    bots.inited = true;
  });
};

export const addBot = async (bot: Parameters<typeof createBot>[0]) => {
  const data = await createBot(bot);
  data && (await loadBots());
  return !!data;
};

export const editBot = async (bot: Parameters<typeof updateBot>[0]) => {
  const data = await updateBot(bot);
  const index = bots.list.findIndex(({ id }) => id === bot.id);
  data && runInAction(() => (bots.list[index] = data));
  return !!data;
};

export const removeBot = async (id: string) => {
  const result = await deleteBot(id);
  result &&
    runInAction(() => (bots.list = bots.list.filter(bot => bot.id !== id)));
};

export const duplicateBot = async (
  userId: string,
  botId: string,
  name: string,
  host: string
) => {
  const data = await copyBot(userId, botId, name);
  if (data) {
    await updateBot({ ...data.bot, host });
    await loadBots();
    await loadUsers();
  }
  return !!data;
};

export default bots;
