import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Descriptions,
  Divider,
  Flex,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import Compact from 'antd/es/space/Compact';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { DefaultOptionType } from 'rc-select/lib/Select';
import cn from 'classnames';

import useLabels from 'labels';
import { invite, sendInvitationEmail } from 'api/bots';
import { copyToClipboard } from 'utils/data';
import { alertSuccess } from 'utils/alert';
import { IProfile } from 'store/profile';
import { IBot } from 'store/bots';
import {
  IUser,
  IFlag,
  editRole,
  IHRInfo,
  defSubscriptionSettings,
} from 'store/users';

import { getInvitationHost, makeInvitationLink } from '../utils/invitation';
import Subscriptions, { TSubscriptionsRef } from '../subscriptions';
import Editing, { TEditingRef } from '../editing';
import Bots, { TBotsRef } from '../bots';

import Owned from './components/owned';
import Chat from './components/chat';
import HR from './components/hr';

import styles from '../../styles.module.scss';

type TRole = 'user' | 'moderator' | 'admin';

export type TUserFormProps = {
  profile: IProfile;
  user: IUser;
  flags: IFlag[];
  bots: IBot[];
  className?: string;
};

const Title: React.FC<{
  text: string;
  actions?: { title: string; onClick?: () => void }[];
}> = ({ text, actions }) => {
  const id = useId();
  return (
    <Flex align="center" gap={10} className={styles.sectionTitle}>
      <Typography.Title level={5}>{text}</Typography.Title>
      <Divider />
      {!!actions?.length && (
        <Compact>
          {actions.map(({ title, onClick }, index) => (
            <Button
              key={`user-form-title-${id}-action-${index}`}
              onClick={onClick}
            >
              {title}
            </Button>
          ))}
        </Compact>
      )}
    </Flex>
  );
};

const UserForm: React.FC<TUserFormProps> = ({
  profile,
  user,
  flags,
  bots,
  className,
}) => {
  const { sm } = useBreakpoint();

  const flaggedBots = useMemo(
    () => flags.find(({ user_id }) => user_id === user.id)?.bot_ids || [],
    [user, flags]
  );
  const [savingRole, setSavingRole] = useState<boolean>(false);
  const handleSaveRole = async () => {
    if (!user) return;
    try {
      setSavingRole(true);
      await editRole(user.id, role === 'admin', role === 'moderator');
    } finally {
      setSavingRole(false);
    }
  };

  const [role, setRole] = useState<TRole>();

  const [botId, setBotId] = useState<string | null>();
  const userSubscriptionsOptions: DefaultOptionType[] = useMemo(
    () =>
      user?.subscriptions
        ? bots
            .filter(({ id: botId }) =>
              user.subscriptions?.find(({ id }) => id === botId)
            )
            .map(({ id, name }) => ({
              value: id,
              label: (
                <>
                  {name}
                  {flaggedBots.includes(id) && <Badge dot />}
                </>
              ),
            }))
        : [],
    [user?.subscriptions, bots, flaggedBots]
  );

  useEffect(() => {
    void (
      user &&
      userSubscriptionsOptions.length === 1 &&
      setBotId(userSubscriptionsOptions[0].value as string)
    );
  }, [user, userSubscriptionsOptions]);

  useEffect(() => {
    if (botId && !user?.subscriptions?.find(({ id }) => id === botId)) {
      setBotId(null);
    }
  }, [user, botId]);

  useEffect(() => {
    if (user) {
      setRole(user.admin ? 'admin' : user.moderator ? 'moderator' : 'user');
    } else {
      setTimeout(() => setBotId(null), 300);
    }
  }, [user]);

  const subscriptionsRef = useRef<TSubscriptionsRef>(null);
  const editingRef = useRef<TEditingRef>(null);
  const botsRef = useRef<TBotsRef>(null);

  const isAdmin = !!profile.admin;

  const isUserAdmin = !!user.admin;
  const isUserModerator = !!user.moderator;

  const profileBots: DefaultOptionType[] = isAdmin
    ? bots.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : profile.user_bots
    ? bots
        .filter(({ id }) => profile.user_bots?.includes(id))
        .map(({ id, name }) => ({ value: id, label: name }))
    : [];

  const bot = bots.find(({ id }) => id === botId);

  const isInvited = user.account_status === 'invited';
  const isHRBot = !!bot?.extra?.hr;

  const [isHrFilled, setHrFilled] = useState(false);
  const handleHrChange = (data: IHRInfo | null) =>
    setHrFilled(!!data?.job_description);

  const canExtractMemory = !!bot?.settings?.extract_memory;

  const isInvitationDisabled = !bot || (isHRBot && !isHrFilled);

  const [generatingLink, setGeneratingLink] = useState(false);
  const handleCopyInvitation = async () => {
    if (!bot) return;
    try {
      setGeneratingLink(true);
      const subscription = user.subscriptions?.find(({ id }) => id === bot.id);
      const data = await invite(
        bot.id,
        user.email,
        subscription?.settings?.language || defSubscriptionSettings.language,
        subscription?.settings?.language_policy_strict ??
          defSubscriptionSettings.language_policy_strict
      );
      data &&
        copyToClipboard(makeInvitationLink(bot, data?.hash)).then(
          result => result && alertSuccess('Invitation link was copied')
        );
    } finally {
      setGeneratingLink(false);
    }
  };

  const [sendingLink, setSendingLink] = useState(false);
  const handleSendInvitation = async () => {
    try {
      setSendingLink(true);
      const result =
        bot &&
        (await sendInvitationEmail(user.id, bot.id, getInvitationHost(bot)));
      !!result && alertSuccess('Invitation link was sent');
    } finally {
      setSendingLink(false);
    }
  };

  const userDataItems = [
    { key: 'name', label: 'Full name', children: user.full_name },
  ];
  if (isUserAdmin || isUserModerator) {
    userDataItems.push({
      key: 'company',
      label: 'Company name',
      children: user.company,
    });
  }

  const labels = useLabels();

  return (
    <div className={cn(className)}>
      <Title
        text={`${labels.user} data`}
        actions={[
          {
            title: 'Edit',
            onClick: () => editingRef.current?.open(user),
          },
        ]}
      />
      <Descriptions size="small" column={1} items={userDataItems} />
      {isAdmin && user?.id !== profile.id && (
        <>
          <Title text="User role" />
          <Space.Compact block className={styles.role}>
            <Select
              value={role}
              options={[
                { value: 'user', label: 'User' },
                { value: 'moderator', label: 'Moderator' },
                { value: 'admin', label: 'Admin' },
              ]}
              disabled={savingRole}
              onChange={setRole}
            />
            <Button
              type="primary"
              loading={savingRole}
              onClick={handleSaveRole}
            >
              Save
            </Button>
          </Space.Compact>
          {user.moderator && (
            <>
              <Title
                text="User owned bots"
                actions={[{ title: 'Add', onClick: botsRef.current?.open }]}
              />
              <Owned bots={bots} user={user} />
            </>
          )}
        </>
      )}
      <Title text="Chats" />
      <Row gutter={[10, 10]}>
        {profileBots.length > 1 && (
          <Col span={24}>
            <Space.Compact block>
              <Select
                className={styles.bots}
                value={botId}
                options={userSubscriptionsOptions}
                disabled={userSubscriptionsOptions.length < 2}
                placeholder="Select bot"
                onChange={setBotId}
              />
              <Button
                onClick={subscriptionsRef.current?.open}
                disabled={isInvited}
              >
                Change
              </Button>
            </Space.Compact>
          </Col>
        )}
        {botId && isHRBot && (
          <Col span={24}>
            <HR userId={user.id} botId={botId} onChange={handleHrChange} />
          </Col>
        )}
        {isInvited && (
          <Col span={24}>
            <Flex align="center" justify="center">
              <Space.Compact direction={sm ? 'horizontal' : 'vertical'}>
                <Button
                  type="primary"
                  loading={generatingLink}
                  disabled={isInvitationDisabled}
                  onClick={handleCopyInvitation}
                >
                  Copy invitation link
                </Button>
                <Button
                  type="primary"
                  loading={sendingLink}
                  disabled={isInvitationDisabled}
                  onClick={handleSendInvitation}
                >
                  Resend invitation email
                </Button>
              </Space.Compact>
            </Flex>
          </Col>
        )}
        {!isInvited && botId && (
          <Col span={24}>
            <Chat
              user={user}
              botId={botId}
              isAdmin={isAdmin}
              isHRBot={isHRBot}
              canExtractMemory={canExtractMemory}
            />
          </Col>
        )}
      </Row>
      <Subscriptions
        ref={subscriptionsRef}
        bots={profileBots}
        userId={user?.id}
        subscriptions={user?.subscriptions}
      />
      <Editing ref={editingRef} />
      <Bots ref={botsRef} user={user} bots={bots} />
    </div>
  );
};

export default UserForm;
