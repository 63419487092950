import React, { forwardRef, useId, useImperativeHandle, useState } from 'react';
import { Col, Divider, Flex, Modal, Spin, Typography } from 'antd';

import { fetchExtractedMemory } from 'api/bots';
import { TBotExtractedMemory } from 'store/bots';

import styles from '../styles.module.scss';

export type TMemoryRef = {
  open: (userId: string) => void;
};

const Memory = forwardRef<TMemoryRef>((_, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TBotExtractedMemory>([]);

  const id = useId();

  const loadData = async (userId: string) => {
    setLoading(true);
    try {
      const result = await fetchExtractedMemory(userId);
      setData(result || []);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: (userId: string) => {
      setData([]);
      setOpened(true);
      loadData(userId);
    },
  }));

  const handleClose = () => setOpened(false);

  return (
    <Modal
      title="Memory"
      cancelText="Close"
      open={opened}
      width={800}
      onCancel={handleClose}
      footer={(_, extra) => <extra.CancelBtn />}
    >
      {loading ? (
        <Flex justify="center" align="center">
          <Spin />
        </Flex>
      ) : (
        data.map(({ title, items }, index) => (
          <Col
            key={`extracted-memory-${id}-section-${index}`}
            span={22}
            offset={1}
          >
            <Flex align="center" gap={10} className={styles.sectionTitle}>
              <Typography.Title level={5}>{title}</Typography.Title>
              <Divider />
            </Flex>
            <Col span={22} offset={1}>
              <Flex align="flex-start" gap={4} vertical>
                {items.map((item, itemIndex) => (
                  <p
                    key={`extracted-memory-${id}-section-${index}-item-${itemIndex}`}
                  >
                    {item}
                  </p>
                ))}
              </Flex>
            </Col>
          </Col>
        ))
      )}
    </Modal>
  );
});

export default Memory;
